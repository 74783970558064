import React from 'react';
import MyNavBar from "./component/MyNavBar";
import { Outlet } from "react-router-dom";
import OffCanvasCookies from "./component/OffCanvasCookies";

function App() {
  return (
    <div id="app">
      <MyNavBar/>
        <div id="main">
            <Outlet />
        </div>
        <OffCanvasCookies/>
    </div>
  );
}

export default App;
