import React from 'react';
import PropTypes from 'prop-types';
import {Tab, Card, Tabs} from "react-bootstrap";
import {getMessagesByUser} from "../repository/ChatMsgRepository";
import {MyMessage} from "../interface/MyMessage";
import TabListView from "./TabList";
import {urlToResizedImageUrl} from "../utils/functions";
import ListChildItem from "./ListChildItem";
import {getShoutsByUser} from "../repository/ShoutRepository";
import {getEventsByUser} from "../repository/EventsRepository";
import {MyEvent} from "../interface/MyEvent";

ReportUserTabs.propTypes = {};

interface Props {
    userId: string
}


function ReportUserTabs(props: Props) {
    const {userId} = props;

    return (
        <Tabs
            defaultActiveKey="chat"
            justify
        >
            <Tab eventKey="chat" title="Chat Message">
                <TabListView<MyMessage> func={() => getMessagesByUser(userId)} renderItem={(item: MyMessage) =>
                    <ListChildItem body={item.text} id={item.id}
                                   imgUrl={item.attachment != null && item.attachment.length > 0 ? item.attachment : ''} title={''}/>
                } queryKey={'messages'}/>
            </Tab>
            <Tab eventKey="event" title="Events">
                <TabListView<MyEvent> func={() => getEventsByUser(userId)} renderItem={(item: MyEvent) =>
                    <ListChildItem body={item.description} id={item.id}
                                   imgUrl={item.imgUrl != null && item.imgUrl.length > 0 ? item.imgUrl : ''} title={item.title}/>
                } queryKey={'events'}/>
            </Tab>
            <Tab eventKey="shout" title="Shout Message">
                <TabListView<MyMessage> func={() => getShoutsByUser(userId)} renderItem={(item: MyMessage) =>
                    <ListChildItem body={item.text} id={item.id}
                                   imgUrl={item.attachment != null && item.attachment.length > 0 ? item.attachment : ''} title={''}/>
                } queryKey={'shouts'}/>

            </Tab>
        </Tabs>
    );
}

export default ReportUserTabs;