import {deleteData, getData, putData} from "./client";
import {User} from "../interface/User";


export async function disableUser(id: string, disable : boolean) : Promise<boolean>{

    try{
        let result = await putData(`${baseUrl}/disable/${id}/${disable}`);
        return  await result.json();
    }catch(e){
        console.log(e);
        throw e;
    }
}

export async function checkAccess() : Promise<boolean>{

    try{
    let result =  await putData(`${baseUrl}/check-access`);
    return  await  result.json();
    }catch(e){
        console.log(e);
        throw e;
    }
}


export async function getUsers(orderBy: string, from : number|null, limit : number) : Promise<User[]>{

    const params: Record<string, any> = {
        'orderBy': orderBy,
        'limit': limit
    };

    if (from !== null) {
        params['from'] = from;
    }
    try{
        let result = await getData(`${baseUrl}/users`, params);
        return  await result.json();
    }catch(e){
        console.log(e);
        throw e;
    }
}

export async function deleteUser() : Promise<boolean>{
    try {
        let result = await deleteData(`${baseUrl}/delete`);
        return await result.json();
    }catch (e) {
        console.log(e);
        throw e;
    }
}



const baseUrl = '/user'



