import React from 'react';
import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {appName, I18nLng, routeName} from "../Constants";
import {Facebook, Instagram} from "react-bootstrap-icons";

function MyNavBar() {

    const {t, i18n} = useTranslation();
    let navWrapper: HTMLDivElement | null;
    let toggleButtonRef: any;

    function handleDocumentClick(e: any) {
        if (toggleButtonRef && !navWrapper?.contains(e.target)) {
            toggleButtonRef.click();
        }
    }

    const onExpand = (expanded: boolean) => {
        if (expanded) {
            document.addEventListener('click', handleDocumentClick, true);
        } else {
            document.removeEventListener('click', handleDocumentClick, true);
        }
    }


    return (
        <div ref={(e: HTMLDivElement | null) => (navWrapper = e)}>
            <Navbar className={"px-3"} ref={(e: HTMLDivElement | null) => (navWrapper = e)} collapseOnSelect
                    onToggle={onExpand} fixed={"top"} bg="primary" variant="dark"
                    expand="lg">
                <>
                    <Navbar.Brand as={Link} to={routeName.home}>
                        <h2 style={{fontWeight: "bold"}} className="p-0 m-0">
                            {appName}
                        </h2>
                    </Navbar.Brand>
                    <Navbar.Toggle ref={(e: any) => (toggleButtonRef = e)} aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav ">
                        <Nav className="me-auto w-100">
                            <Nav.Link eventKey={0} as={Link} to={routeName.root}>{t('nav_bar.btn_home')}</Nav.Link>
                            <Nav.Link eventKey={1} as={Link}
                                      to={routeName.root + routeName.app}>{t('nav_bar.btn_app')}</Nav.Link>
                            <Nav.Link eventKey={2} as={Link}
                                      to={routeName.root + routeName.faq}>{t('nav_bar.btn_faq')}</Nav.Link>
                            <Nav.Link eventKey={3} as={Link}
                                      to={routeName.root + routeName.about}>{t('nav_bar.btn_about')}</Nav.Link>

                            <NavDropdown menuVariant={"dark"} title={t('nav_bar.dropdown_legal')}
                                         id="nav-dropdown-legal">
                                <NavDropdown.Item as="a" href={routeName.root + '#/' + routeName.terms}
                                                  rel="noopener noreferrer"
                                                  target="_blank">{t('nav_bar.btn_terms')}</NavDropdown.Item>
                                <NavDropdown.Item as="a" href={routeName.root + '#/' + routeName.privacy}
                                                  rel="noopener noreferrer"
                                                  target="_blank">{t('nav_bar.btn_privacy')}</NavDropdown.Item>
                                <NavDropdown.Item as="a" href={routeName.root + '#/' + routeName.cookies}
                                                  rel="noopener noreferrer"
                                                  target="_blank">{t('nav_bar.btn_cookies')}</NavDropdown.Item>
                            </NavDropdown>

                            <Nav.Link as={Link}
                                      to="https://www.facebook.com/SonR.dating" rel="noopener noreferrer" target="_blank"> <Facebook/></Nav.Link>
                            <Nav.Link as={Link}
                                      to="https://www.instagram.com/sonr.sv/" rel="noopener noreferrer" target="_blank"> <Instagram/></Nav.Link>
                            <NavDropdown menuVariant={"dark"} className="ms-lg-auto"
                                         title={I18nLng.get(i18n.resolvedLanguage)?.nativeName ?? ''}
                                         id="nav-dropdown-legal">
                                {
                                    Array.from(I18nLng.keys()).filter(e => e !== i18n.language).map((lng, index) =>
                                        <NavDropdown.Item onClick={() => i18n.changeLanguage(lng)}
                                                          key={index}>{I18nLng.get(lng)?.nativeName ?? ''}</NavDropdown.Item>)
                                }
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </>
            </Navbar>
        </div>

    );
};

export default MyNavBar;
