import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from "react-query";
import MySpinner from "./MySpinner";
import {Alert} from "react-bootstrap";


TabList.propTypes = {
    queryKey: PropTypes.string.isRequired,
    func: PropTypes.func.isRequired,
};

interface Props<T> {
    queryKey: string,
    func: () => Promise<T[]>,
    renderItem: (item: T) => React.ReactNode
}

function TabList<T>(props: Props<T>){
    const { queryKey, func, renderItem } = props;
    const {isLoading, isError, data, error} = useQuery(queryKey,  func)

    if (isLoading) {
        return <MySpinner/>
    }

    if (isError) {
        return <Alert>
            {error?.toString()}
        </Alert>
    }


    return (
        <div className={'d-flex flex-column'}>
            {data && data.map((item: T) => renderItem(item))}
        </div>
    );
}

export default TabList;