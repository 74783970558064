import {auth} from './FirebaseSetup'; // Import firebaseAuth from your setup
import {FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, signInWithPopup} from '@firebase/auth';

// Google Sign-In Method
export const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
        // Handle the successful result
        return await signInWithPopup(auth, provider);
    } catch (error) {
        // Handle errors
        console.error("Error signing in with Google: ", error);
        throw error;
    }
};

export  const  signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
        // Handle the successful result
        return await signInWithPopup(auth, provider);
    } catch (error) {
        // Handle errors
        console.error("Error signing in with Facebook: ", error);
        throw error;
    }
}

export  const signInWithAppleId = async () => {
    const provider = new OAuthProvider('apple.com');
    try {
        // Handle the successful result
        return await signInWithPopup(auth, provider);
    } catch (error) {
        // Handle errors
        console.error("Error signing in with Apple: ", error);
        throw error;
    }
}
