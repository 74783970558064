import React from 'react';
import {User} from "../interface/User";
import {Image} from "react-bootstrap";
import {ImageSize} from "../Constants";
import {urlToResizedImageUrl} from "../utils/functions";


UserAvatarName.defaultProps = {
    size: 75
};


interface Props {
    user: User
    size: number
}

function UserAvatarName(props: Props) {
    const {user, size} = props;
    const {profileImgUrl} = user;

    const profileIcon = () => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="currentColor"
                     className="bi bi-person-circle" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
            <path fillRule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
        </svg>);
    }


    return (
        <div className="d-flex">
            <div className="flex-shrink-1">
                {profileImgUrl === null || profileImgUrl.length < 1 ? profileIcon() :
                    <Image roundedCircle={true} width={size} height={size} style={{objectFit: "cover"}}
                           src={urlToResizedImageUrl(user.profileImgUrl, ImageSize.sm)}/>}


            </div>
            <div className="flex-grow-1">
                <div className="d-flex flex-column align-items-center">
                    <h5>{user.displayName}</h5>
                </div>
            </div>
        </div>
    );
}

export default UserAvatarName;