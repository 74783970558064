import { initializeApp } from "@firebase/app";
import {getAuth} from  '@firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyA-nf_wPmwHhup9t3l3I-np9gZRoItz9z4",
    authDomain: "nearby-2a601.firebaseapp.com",
    projectId: "nearby-2a601",
    storageBucket: "nearby-2a601.appspot.com",
    messagingSenderId: "909959110938",
    appId: "1:909959110938:web:97b81c1ab60ecb9364cca3",
    measurementId: "G-60GRKJDX8F"
};

const  app = initializeApp(firebaseConfig);
export const  auth = getAuth(app);
