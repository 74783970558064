import React, {useState} from 'react';
import {useTransition, animated} from '@react-spring/web';


// const textData = [
//     "No \"premium\" fees – Equal opportunities for everyone.",
//     "Find a date today – No more waiting around.",
//     "No more thumb cramps – Instantly view everyone nearby without endless swiping.",
//     "Connect with nearby people – Quickly and easily reach potential matches.",
// ];


interface Props {
    texts : string[]
}

function RepeatingAnimatedText(props : Props) {
    const {texts} = props;
    const [index, setIndex] = useState(0);

    // Define the animated spring for the text
    const fadingTextPropsTransition = useTransition(texts[index], {
        from: { opacity: 0, },
        enter: { opacity: 1, delay: 500 },
        leave: { opacity: 0},

    });

    // Increment the text index every 3 seconds
    React.useEffect(() => {
        const interval = setInterval(() => {
            setIndex((state) => (state + 1) % texts.length);
        }, 5000);
        return () => clearInterval(interval);
    }, []);



    return (
        <div className="fading-text-container w-100 h-100">
            {fadingTextPropsTransition((styles, text) => (
                <animated.h1 className="fading-text" style={styles}>
                    {text}
                </animated.h1>
            ))}
        </div>
    );
}

export default RepeatingAnimatedText;