import React from 'react';
import RepeatingAnimatedText from "../component/RepeatingAnimatedText";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ScaleFadeText from "../component/ScaleFadeText";
import {useNavigate} from "react-router-dom";
import {routeName} from "../Constants";

HomePage.propTypes = {};


function HomePage() {
    const {t, i18n} = useTranslation();
    const rotatingText = t('page.rotations_texts');

    const navigate = useNavigate();
    // Check if the rotatingText is null before accessing it
    const texts = rotatingText ? JSON.parse(rotatingText) : [];

    const handleClick = () => {
        navigate(routeName.root + routeName.app, {replace: true});
    };

    return (
        <Container className={"d-flex flex-column justify-content-center pt-5"}>
            <Row>
                <Col>
                    <div onClick={handleClick} style={{cursor:'pointer'}}>
                        <ScaleFadeText text={'SonR'} containerClassName={"sonr-container"} textClassName={"sonr"}/>
                    </div>
                </Col>
            </Row>
            <Row className='pt-5'>
                <Col>
                    <RepeatingAnimatedText texts={texts}/>
                </Col>
            </Row>
        </Container>
    );
}

export default HomePage;