import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Container, Modal} from "react-bootstrap";
import {getUsers} from "../../repository/UserRepository";
import {User} from "../../interface/User";
import MySpinner from "../../component/MySpinner";
import {order} from "../../Constants";
import UserProfileCard from "../../component/UserProfileCard";
import UserListItem from "../../component/UserListItem";
import UserListHeader from "../../component/UserListHeader";

UsersPage.propTypes = {};


const limit = 100;

function UsersPage() {
    const [orderBy, setOrderBy] = useState<string>(order.createdAt);
    const [endReached, setEndReached] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>();
    const [error, setError] = useState<string | null>(null);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [checkUser, setCheckUser] = useState<User | null>(null);

    useEffect(() => {
        async function initUserList() {
            await fetchUsers(null, users, orderBy);
        }

        initUserList().then();

    }, [])


    async function fetchUsers(from: number | null, oldState: User[], orderBy: string) {
        if (isLoading) return;
        setIsLoading(true);
        try {
            let result = await getUsers(orderBy, from, limit);
            setUsers(() => {
                const uniqueUsers = result.filter((newUser) => {
                    return !oldState.some((existingUser) => existingUser.id === newUser.id);
                });
                return [...oldState, ...uniqueUsers];
            });
            if (result.length !== limit) {
                setEndReached(true);
            }
        } catch (e: any) {
            setError(e.toString());
        } finally {
            setIsLoading(false);
        }
    }


    async function loadMore() {
        if (orderBy === order.createdAt) {
            const minCreatedAt = Math.min(...users.map((user) => user.createdAt));
            await fetchUsers(minCreatedAt, users, orderBy);
        } else if (orderBy === order.lastActive) {
            const minLastActive = Math.min(...users.map((user) => user.lastActive));
            await fetchUsers(minLastActive, users, orderBy);
        }
    }


    return (
        <Container>
            <UserListHeader users={users} fetchUsers={fetchUsers} orderBy={orderBy} setOrderBy={setOrderBy}/>
            <div className={"d-flex flex-column"} style={{maxHeight: "100vh", overflowY: "scroll"}}>
                {
                    users.map((u) => {
                        return <UserListItem key={u.id} user={u} checkUser={checkUser} setCheckUser={(u) => {
                            if (u.id === checkUser?.id) {
                                setCheckUser(null);
                            } else {
                                setCheckUser(u);
                            }
                        }}
                                             showUser={(userId: string) => setSelectedUserId(userId)}/>
                    })
                }
                {error && <Alert variant={'warning'}>{error}
                </Alert>}
                {isLoading && <Card>
                    <div className={'d-flex flex-row align-items-center justify-content-center'}>
                        Loading.. <MySpinner/>

                    </div>

                </Card>}
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
                {endReached ? <Alert variant={"success"}>Nothing more to load</Alert> :
                    <Button onClick={() => loadMore()}>Load more</Button>}
            </div>
            {
                selectedUserId && <Modal show={true} fullscreen onHide={() => setSelectedUserId(null)}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <UserProfileCard user={users.find((e) => e.id === selectedUserId)!} callback={(user: User) => {
                            setUsers((oldState) => {
                                return oldState.map((oldUser) => {
                                    if (oldUser.id === user.id) {
                                        return user; // Replace the matching user with the new user object
                                    }
                                    return oldUser; // Keep the existing user object
                                });
                            });
                        }}/>
                    </Modal.Body>
                </Modal>
            }

        </Container>
    );
}

export default UsersPage;