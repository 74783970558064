import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import {useInView} from "react-intersection-observer";
import FadeTransSection from "../component/FadeTransSection";
import {LinkItEmail} from "react-linkify-it";

AboutPage.propTypes = {};

function AboutPage() {
    const {t, i18n} = useTranslation();
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });
    const textData = t('page.about.texts');
    const texts: string[] = textData ? JSON.parse(textData) : [];
    return (
        <Container ref={ref}>
            <FadeTransSection start={inView} fade delay={500}>

                <Row>
                    <Col>
                        <h2>{t('page.about.title')}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {texts.map((e, index) =>
                            <LinkItEmail>
                                <p key={index}>
                                    {e}
                                </p>

                            </LinkItEmail>
                            )}
                    </Col>
                </Row>
            </FadeTransSection>

        </Container>
    );
}

export default AboutPage;