import React from 'react';
import PropTypes from 'prop-types';
import {ComposableMap, Geographies, Geography, Marker} from "react-simple-maps"

MySimpleMap.propTypes = {};

interface Props {
    latLng: number[]
}

function MySimpleMap(props: Props) {
    const {latLng} = props;


    const zoom = 1;
    const center: [number, number] = [latLng[1], latLng[0]];
    return (
        <ComposableMap
            projection="geoMercator"
            width={500}
            height={500}
            projectionConfig={{
                center: center,
                scale: 1000 * zoom, // Adjust the scale value to control the zoom level
            }}
        >
            <Geographies geography={require("../assets/world-countries.json")}>
                {({geographies}) =>
                    geographies.map((geo) => (
                        <Geography key={geo.rsmKey}
                                   geography={geo}

                                   fill="#DDD"
                                   stroke="#FFF"

                        />
                    ))
                }
            </Geographies>
            <Marker  coordinates={[latLng[1], latLng[0]]}>
                <circle r={8} fill="#F53"/>
            </Marker>
        </ComposableMap>
    );
}

export default MySimpleMap;