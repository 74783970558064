import React from 'react';
import {User} from "../interface/User";
import UserAvatarName from "./UserAvatarName";


interface Props {
    user: User
}

function UserProfileMail(props: Props) {
    const {user} = props;

    return (
        <div className="d-flex flex-column">
            <UserAvatarName size={45} user={user}/>
            <span>{user.email}</span>
        </div>
    );
}

export default UserProfileMail;