import React, {useState, useEffect} from 'react';
import {Alert, Button, Col, Form, Modal, ModalProps, Row} from 'react-bootstrap';
import {MyPreference} from "../interface/MyPreference";
import {createPreference} from "../repository/PreferenceRepository";
import MySpinner from "../component/MySpinner";

PrefAddModal.propTypes = {};

interface Props extends ModalProps {
    ids: string[];
    lngKeys: string[];
    onAdd: (pref: MyPreference) => void;
}

function validateText(text: string): boolean {
    // Regular expression to match camelCase without special characters and whitespace
    const pattern = /^[a-zA-Z][a-zA-Z\d]*$/;

    // Check if the input matches the pattern
    return pattern.test(text);
}

function validateLngKeys(lngKeys: Record<string, string>): boolean {
    return Object.values(lngKeys).every(value => value !== null && value !== '');
}

function keyIsAvailable(text: string, keys: string[]) {
    if (keys.find((e) => e.toLowerCase() === text.toLowerCase())) {
        return 'Key name: ' + text + ' already taken';
    }
    return null;
}

function getInitialState(lngKeys: string[]) {
    return {id: '', values: Object.fromEntries(lngKeys.map(key => [key, '']))};
}

function PrefAddModal(props: Props) {
    const {ids, lngKeys, onAdd, ...modalProps} = props;
    const [pref, setPref] = useState<MyPreference>(getInitialState(lngKeys));
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setPref(getInitialState(lngKeys));
    }, [modalProps.show]);

    useEffect(() => {
        setError(keyIsAvailable(pref?.id, ids));
    }, [pref?.id, ids]);

    const handleAddClick = async () => {
        const idIsValid = validateText(pref?.id);
        const lngKeysValid = validateLngKeys(pref.values);
        if (!idIsValid) {
            setError('Invalid text format. Please enter text in camelCase without special characters or whitespace.');
        } else if (!lngKeysValid) {
            setError('Missing translation');
        } else {
            if (isLoading) return;
            setIsLoading(true);
            try {
                const result = await createPreference(pref);
                props.onAdd(result);
                setPref(getInitialState(lngKeys));
            } catch (e: any) {
                setError(e.toString());
            } finally {
                setIsLoading(false);
            }
        }
    };


    const handleValueChange = (key: string, value: string) => {
        setPref(oldPref => ({
            ...oldPref,
            values: {
                ...oldPref.values,
                [key]: value
            }
        }));
    };

    return (
        <Modal className={"text-dark"} {...modalProps} size="lg" aria-labelledby="contained-modal-title-vcenter"
               centered>
            {isLoading ? <div className={"d-flex justify-content-center align-items-center"}><MySpinner/></div> : <>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Add preference</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control className="mb-3"
                                  onChange={(e) => setPref((oldPref) => ({...oldPref, id: e.target.value}))}
                                  type="text"
                                  placeholder="Preference Id"
                    />
                    {Object.entries(pref.values).map(([key, value]) => (
                        <Form.Group as={Row} className="mb-3" controlId={key} key={key}>
                            <Form.Label column sm="2">
                                {key}
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    placeholder={key}
                                    value={value}
                                    onChange={(e) => handleValueChange(key, e.target.value)}
                                />
                            </Col>
                        </Form.Group>
                    ))}

                    {error && <Alert variant={'danger'}>{error}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Cancel</Button>
                    <Button onClick={handleAddClick}>Add</Button>
                </Modal.Footer>
            </>}
        </Modal>
    );
}

export default PrefAddModal;
