import React, {useEffect, useState} from "react";
import {auth} from "../FirebaseSetup";
import {AuthContext} from "../context/AuthContext";
import firebase from "@firebase/auth";
import {checkAccess} from "../repository/UserRepository";

function AuthProvider(props: React.PropsWithChildren<{}>) {
    const [user, setUser] = useState<firebase.User | null>(null);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState(false);

    
    useEffect(() => {
        return auth.onAuthStateChanged((firebaseUser) => {
            setUser(firebaseUser);
            firebaseUser?.getIdTokenResult().then(async value => {
                let isAdmin = value.claims.admin ?? false;
                if (!isAdmin) {
                    isAdmin = await checkAccess();
                    if (isAdmin){
                        firebaseUser?.getIdTokenResult(true);
                    }
                }
                setIsAdmin(isAdmin);
            });

            if (!initialized) {
                setInitialized(true);
            }
            console.log(`onAuthStateChanged user: ${firebaseUser}`);
        });

    }, [initialized]);

    return <AuthContext.Provider value={{user, initialized, isAdmin}} {...props}/>;

}

export default AuthProvider;