import React from 'react';
import PropTypes from 'prop-types';

AppImageRow.propTypes = {};


interface Props {
    images: string[]
}

function AppImageRow(props: Props) {
    const {images} = props;


    return (
        <div className={"d-flex justify-content-center mb-3 w-100"}>
        <div className="d-flex" >
            {images.map((e, index) => {
                return <div key={index} className={'px-1'}>
                    <img className={" rounded-3"} src={e} width={"100%"} alt={"screenshot " + index}/>
                </div>
            })}
        </div>

        </div>
    );
}

export default AppImageRow;