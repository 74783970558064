import React from 'react';
import {useSpring, animated} from '@react-spring/web';
import {useInView} from 'react-intersection-observer';

interface Props {
    fade?: boolean;
    translate?: string;
    delay?: number
    children?: React.ReactNode;
    start: boolean
}

function FadeTransSection(props: Props) {
    const {fade = false, translate = '0px', delay = 0, start} = props;



    const animation = useSpring({
        from: {opacity: fade ? 0 : 1, transform: `translateX(${translate})`},
        to: {opacity: start ? 1 : 0, transform: start ? 'translateX(0)' : `translateX(${translate})`},
        delay: delay
    });


    return (
        <animated.div style={animation}>
            {props.children}
        </animated.div>
    );
}

export default FadeTransSection;
