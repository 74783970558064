import {AppleLoginButton, createButton, FacebookLoginButton, GoogleLoginButton} from "react-social-login-buttons";
import React from "react";
import {signInWithAppleId, signInWithFacebook, signInWithGoogle} from "../FirebaseAuthentication";

function LoginList() {
    return <div className={"d-flex flex-column"}>
        <GoogleLoginButton onClick={
            () => signInWithGoogle()

        }/>
        <AppleLoginButton onClick={
            () => signInWithAppleId()
        }/>

    </div>;
}

export default LoginList