import React, {useState} from 'react';
import {Alert, Button, Card, Col, Image, Row} from "react-bootstrap";
import UserProfileMail from "./UserProfileMail";
import {urlToResizedImageUrl} from "../utils/functions";
import {ImageSize} from "../Constants";
import MySpinner from "./MySpinner";
import ReportUserTabs from "./ReportUserTabs";
import {disableUser} from "../repository/UserRepository";
import {User} from "../interface/User";

UserProfileCard.propTypes = {};

interface Props {
    user: User
    callback: Function
}

function UserProfileCard(props: Props) {
    const {user, callback} = props;
    const [isBusy, setIsBusy] = useState(false);

    async function handleClick() {
        if (isBusy) return;
        setIsBusy(true);
        try {
            let value = await disableUser(user.id, !user.disabled);
            let updatedUser = {
                ...user,
                disabled: value
            }
            callback(updatedUser)
        } catch (e) {
            console.log(e)
        } finally {
            setIsBusy(false);
        }

    }

    return (
        <Card text={'dark'}>
            <Alert className="text-center"
                   variant={user.disabled ? "danger" : "success"}>User {user.disabled ? 'Disabled' : 'Enabled'}</Alert>
            <Card.Body>
                <Card.Title>Reported</Card.Title>
                <UserProfileMail user={user}/>
                <Row className={'mb-3'}>
                    <Col style={{overflowX: 'scroll'}} className={'d-flex flex-row'}>
                        {user.imageUrls.map((url, index) => {
                            return <Image height={'500px'} key={index} style={{objectFit: "cover"}}
                                          src={urlToResizedImageUrl(url, ImageSize.md)}/>
                        })}
                    </Col>

                </Row>
                <Row className={'mb-3'}>
                    <Col>
                        {<Card className={'p-2'}>
                            {user.bio.length > 0 ? user.bio : 'Bio is empty'}
                        </Card>}
                    </Col>
                </Row>
                {isBusy &&
                <div className="d-flex justify-content-center">
                    <MySpinner/>
                </div>
                }
                {!isBusy &&
                <Row className={'mb-5'}>
                    <Col className={'d-flex justify-content-center'}>
                        <Button
                            onClick={() => handleClick()}
                            variant={user.disabled ? "info" : "warning"}>{user.disabled ? 'Enable' : 'Disable'} user</Button>
                    </Col>
                </Row>
                }
                <ReportUserTabs userId={user.id}/>

            </Card.Body>
        </Card>
    );
}


export default UserProfileCard;