import React, {FormEvent, FormEventHandler, ReactEventHandler, useState} from 'react';
import {Alert, Button, Form} from "react-bootstrap";
import {auth} from "../FirebaseSetup";
import {signInWithEmailAndPassword} from "@firebase/auth";
import MySpinner from "./MySpinner";

LoginForm.propTypes = {};

function LoginForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    function handleLogin(event : FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setIsLoading(true)
        setError(null)
        signInWithEmailAndPassword(auth, email, password)
            .then()
            .catch(error => setError(error.toString()))
            .finally(() => setIsLoading(false));
    }

    if (isLoading) {
        return <MySpinner/>
    }

    return (
        <Form style={{width: "300px"}} onSubmit={handleLogin}>
            <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter email"/>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password"/>
            </Form.Group>
            {
                error &&
                <Alert className="text-wrap" variant={"warning"}>{error}</Alert>
            }

            <div className="d-flex justify-content-end">
                <Button variant="primary" type="submit">
                    Login
                </Button>
            </div>
        </Form>
    );
}

export default LoginForm;