import React, {useContext, useEffect, useState} from 'react';
import {Outlet, useNavigate, useLocation} from "react-router-dom";
import {Alert, Button, Card, Container} from "react-bootstrap";
import {AuthContext} from "../../context/AuthContext";
import MySpinner from "../../component/MySpinner";
import {auth} from "../../FirebaseSetup";
import LoginForm from "../../component/LoginForm";


function AdminPage() {
    const {user, isAdmin, initialized} = useContext(AuthContext);
    const [isBusy, setIsBusy] = useState(initialized);
    const navigate = useNavigate();
    const location = useLocation();




    // useEffect(() => {
    //
    //     if (isAdmin && !location.pathname.includes(routeName.report)) {
    //         navigate(routeName.report);
    //     }
    // }, [initialized, isAdmin, location, navigate]);
    //

    if (isBusy) {
        return <Container className="d-flex justify-content-center align-items-center">
            <MySpinner/>
        </Container>
    }


    async function handleSignOut() {
        setIsBusy(true);
        try {
            await auth.signOut().then(
                () => navigate("")
            );
        } catch (e) {

        } finally {
            setIsBusy(false);
        }
    }

    return (
        <Container style={{minHeight: "100vh"}}
                   className="d-flex flex-column  justify-content-center align-items-center text-light">
            {!user && <div className="d-flex">
                <LoginForm/>
            </div>}
            {user && !isAdmin && <Alert variant={"warning"}>401 Unauthorized</Alert>}
            {user && isAdmin && <Outlet/>}
            {user &&

            <div className="d-flex mt-5 mb-5 w-100 justify-content-evenly">
                {
                    !location.pathname.endsWith('admin') &&
                    <Button onClick={() => navigate(-1)}>Go back</Button>
                }
                <Button onClick={handleSignOut}>Sign out</Button>
            </div>}
        </Container>
    );
}

export default AdminPage;