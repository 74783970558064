import React, {ChangeEvent, useState} from 'react';
import {Carousel} from "react-bootstrap";


AppImgCarousel.propTypes = {
    
};



interface Props {
    images : string[]
}

function AppImgCarousel(props : Props) {
    const {images} = props;
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };



    return (
        <Carousel  className="rounded mb-3"  controls={false} indicators={false} interval={3000} activeIndex={index} onSelect={handleSelect}>
            {
              images.map((e,index)=>{
                return  <Carousel.Item key={index}>
                      <img
                          className="d-block w-100 rounded"
                          src={e}
                          alt={"App screenshot " + index}
                      />
                  </Carousel.Item>
              } )

            }

        </Carousel>
    );
}

export default AppImgCarousel;