import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import {LinkItEmail} from "react-linkify-it";

interface Term {
    title:string,
    body:string
}

function TermsPage() {
    const {t} = useTranslation();
    const termData = t('page.terms.list');
    const terms :Term[] = termData ? JSON.parse(termData) : [];
    return (
        <LinkItEmail>
            <Container  className="my-5 p">
                <Row>
                    <Col>
                        <h2>{t('page.terms.title')}</h2>
                        <p>{t('page.terms.subtitle')}</p>
                        <div className="d-flex flex-column">
                            {terms.map((e,index)=> <div key={index} >
                                <div className={'d-flex flex-column mb-3'}>
                                    <h4>{e.title}</h4>
                                    <p>{e.body}</p>
                                </div>
                            </div> )}
                        </div>
                        <div>
                            <h4>Your Rights under the GDPR</h4>
                            <p>The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.</p>
                            <p>You have the right under this Privacy Policy, and by law if You are within the EU, to:</p>
                            <ul>
                                <li>Request access to Your Personal Data. The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly in your profile page on our app, click on the 3-dot-icon bottom right and click "Settings" then "Delete Account" then follow the instructions to delete any personal data that we may have on you. If you are unable to perform these actions yourself, please contact us on  <a href="mailto:info@sonr.app">info@sonr.app</a> to assist you.</li>
                                <li>Request correction of the Personal Data that We hold about You.&nbsp;You have the right to have any incomplete or inaccurate information We hold about You corrected.</li>
                                <li>Object to processing of Your Personal Data.&nbsp;This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.</li>
                                <li>Request erasure of Your Personal Data.&nbsp;You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.</li>
                                <li>Request the transfer of Your Personal Data.&nbsp;We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.</li>
                                <li>Withdraw Your consent.&nbsp;You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.</li>
                            </ul>
                        </div>
                        <p>
                            {t('page.terms.footer')}
                        </p>
                    </Col>
                </Row>
            </Container>
        </LinkItEmail>

    );
}

export default TermsPage;