import React from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonGroup} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {routeName} from "../../Constants";

AdminMenu.propTypes = {

};

function AdminMenu() {
    const navigate = useNavigate();
    return (
        <div>
            <ButtonGroup vertical>
                <Button onClick={()=>  navigate(routeName.report)}>Report</Button>
                <Button onClick={()=>  navigate(routeName.users)}>Users-listing</Button>
                <Button onClick={()=>  navigate(routeName.preference)}>Preferences</Button>
            </ButtonGroup>
        </div>
    );
}

export default AdminMenu;