import {getData} from "./client";
import {MyMessage} from "../interface/MyMessage";
import {MyEvent} from "../interface/MyEvent";


export async function getEventsByUser(id: string) : Promise<MyEvent[]>{

    try{
        let result = await getData(`${baseUrl}/by-user/${id}`);
       return  await result.json();
    }catch(e){
        console.log(e);
        throw e;
    }
}




const baseUrl = '/event'



