import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {Button} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";
import {routeName} from "../Constants";


OffCanvasCookies.propTypes = {};

const localStorageKey = 'approved-cookies';


function OffCanvasCookies({...props}) {
    const [show, setShow] = useState(() => showCookiesCanvas(localStorageKey));
    const {t, i18n} = useTranslation();
    return (
        <Offcanvas className={'text-dark d-flex flex-column p-3'} style={{height: 'auto'}} placement={'bottom'}
                   show={show} {...props}>
            <Offcanvas.Title>{t('off_canvas_cookies.title')}</Offcanvas.Title>
            <Offcanvas.Body className={'d-flex'}>
                <div className={'pe-5'}>
                    <p>
                        <Trans
                            i18nKey='off_canvas_cookies.body'
                            t={t}
                            values={{link : t('page.cookies.title')}}
                            components={{
                                policyLink:
                                    <a href={routeName.root + '#/' + routeName.cookies} target="_blank"
                                       rel="noopener noreferrer">
                                    </a>
                            }}
                        />
                    </p>

                </div>
                <div>
                    <Button onClick={() => {
                        handleClick();
                    }}>{t('off_canvas_cookies.btn_ok')}</Button>

                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );

    function handleClick() {
        const now = new Date();
        const unixTimeMs = now.getTime();
        localStorage.setItem(localStorageKey, JSON.stringify(unixTimeMs));
        setShow(false);
    }

    function showCookiesCanvas(localStorageKey: string): boolean {
        const storedTimestamp = Number(localStorage.getItem(localStorageKey));
        const now = new Date().getTime();
        const diffInHours = Math.abs(now - storedTimestamp) / (1000 * 60 * 60);
        return diffInHours > 24;
    }
}

export default OffCanvasCookies;