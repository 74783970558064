import React from 'react';
import PropTypes from 'prop-types';
import {MyMessage} from "../interface/MyMessage";
import {Card} from "react-bootstrap";
import {urlToResizedImageUrl} from "../utils/functions";

ListChildItem.propTypes = {

};

interface Props {
    id:string
    imgUrl:string
    title:string
    body:string
}

function ListChildItem(props : Props) {
    const{id,imgUrl, body, title}=props


        return <Card key={id}>
            {imgUrl != null && imgUrl.length > 3 &&
            <Card.Img variant="top" src={urlToResizedImageUrl(imgUrl)}/>
            }
            <Card.Body>
                {title.length > 0 &&  <Card.Header>{title}</Card.Header>}
                <Card.Text>
                    {body}
                </Card.Text>
            </Card.Body>
        </Card>;

}

export default ListChildItem;