import React, {MutableRefObject, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useParams} from "react-router-dom";
import {Container} from "react-bootstrap";
import {routeName} from "../Constants";
import HomePage from "./HomePage";
import AppPage from "./AppPage";
import FaqPage from "./FaqPage";
import AboutPage from "./AboutPage";
import TermsPage from "./TermsPage";
import PrivacyPage from "./PrivacyPage";

MainContent.propTypes = {};

type Params = {
    arg?: string
}

function MainContent() {
    const params = useParams<Params>();
    const {arg} = params;
    const homeRef = useRef<null | HTMLDivElement>(null);
    const appRef = useRef<null | HTMLDivElement>(null);
    const faqRef = useRef<null | HTMLDivElement>(null);
    const aboutRef = useRef<null | HTMLDivElement>(null);
    const privacyRef = useRef<null | HTMLDivElement>(null);


    useEffect(() => {
        function scrollToRef(ref: MutableRefObject<HTMLDivElement | null>) {
            const element = ref.current;
            if (element) {
                const top = element.offsetTop - 100;
                window.scrollTo({ top, behavior: 'smooth' });
            }
        }


        switch (arg ?? routeName.home) {
            case routeName.home:
                window.scrollTo(0, 0);
                break;
            case routeName.app:
                scrollToRef(appRef);
                break;
            case routeName.about:
                scrollToRef(aboutRef);
                break;
            case routeName.faq:
                scrollToRef(faqRef);
                break;
        }


    }, [arg, params])


    return (
        <Container id={'main-content'}>
            <div ref={homeRef}>
                <HomePage/>
            </div>
            <div ref={appRef}>
                <AppPage/>
            </div>
            <div ref={aboutRef}>
                <AboutPage/>
            </div>
            <div ref={faqRef}>
                <FaqPage/>
            </div>
        </Container>
    );
}

export default MainContent;