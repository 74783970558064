import React, {useState} from 'react';
import {Alert, Button, Modal, ModalProps} from 'react-bootstrap';
import {MyPreference} from "../interface/MyPreference";
import {deletePreference} from "../repository/PreferenceRepository";
import MySpinner from "../component/MySpinner";

PrefDeleteModal.propTypes = {};

interface Props extends ModalProps {
    pref: MyPreference | null
    onDelete: () => void;
}

function PrefDeleteModal(props: Props) {
    const {onDelete, pref, ...modalProps} = props;
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const handleDeleteClick = async () => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            await deletePreference(pref!.id);
            props.onDelete();
        } catch (e: any) {
            setError(e.toString());
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <Modal className={"text-dark"} {...modalProps} size="lg" aria-labelledby="contained-modal-title-vcenter"
               centered>
            {isLoading ? <div className={"d-flex justify-content-center align-items-center"}><MySpinner/></div> : <>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Delete preference</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Delete: {JSON.stringify(pref)}?</p>
                    {error && <Alert variant={'danger'}>{error}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Cancel</Button>
                    <Button onClick={handleDeleteClick}>Delete</Button>
                </Modal.Footer>
            </>}
        </Modal>
    );
}

export default PrefDeleteModal;
