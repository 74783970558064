import React from 'react';
import PropTypes from 'prop-types';
import {Image} from "react-bootstrap";


ImageLink.propTypes = {
    url: PropTypes.string,
    imageSrc: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string
};

interface Props {
    url: string,
    imageSrc: string
    height?: string
    width?: string
}

function ImageLink(props: React.PropsWithChildren<Props>) {
    const {url, imageSrc, height, width} = props;

    return (
        <a href={url} target="_blank" rel="noreferrer noopener">
            <Image style={{height, width}} src={imageSrc}/>
        </a>
    );
}

export default ImageLink;