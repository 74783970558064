import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import {useInView} from "react-intersection-observer";
import FadeTransSection from "../component/FadeTransSection";
import TranslatedTextWithLink from "../component/TranslatedTextWithLink";

interface Faq {
    q:string
    a:string
}

function FaqPage ()  {
    const {t, i18n} = useTranslation();
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });
    const faqData = t('page.faq.list');
    const faqs :Faq[] = faqData ? JSON.parse(faqData) : [];
    return (
        <Container ref={ref} id="faq" className="my-5">
            <FadeTransSection start={inView} fade delay={500}>
                <Row>
                    <Col>
                        <h2>{t('page.faq.title')}</h2>
                        <ul>
                            {faqs.map((e,index)=> <li key={index}>
                                <div className={'d-flex flex-column'}>
                                    <b>{e.q}</b>
                                    <TranslatedTextWithLink text={e.a}/>

                                </div>
                            </li> )}
                        </ul>
                    </Col>
                </Row>
            </FadeTransSection>

        </Container>
    );
}

export default FaqPage;
