import {deleteData, getData, postData, putData} from './client';
import { MyPreference } from '../interface/MyPreference';
import {PrefUpdateDto} from "../interface/PrefUpdateDto";

const baseUrl = '/preference';

export async function getAllPreference(): Promise<MyPreference[]> {
    try {
        const result = await getData(`${baseUrl}/get-all`);
        return await result.json();
    } catch (error) {
        console.log(error);
        throw new Error('Failed to fetch preferences');
    }
}

export async function updatePreference(pref : PrefUpdateDto): Promise<MyPreference> {
    try {
        const result = await putData(`${baseUrl}/update`, pref );
        return await result.json();
    } catch (error) {
        console.log(error);
        throw new Error('Failed to fetch preferences');
    }
}

export async function createPreference(pref : MyPreference): Promise<MyPreference> {
    try {
        const result = await postData(`${baseUrl}/create`, pref );
        return await result.json();
    } catch (error) {
        console.log(error);
        throw new Error('Failed to fetch preferences');
    }
}

export async function deletePreference(id: string): Promise<void> {
    try {
        await deleteData(`${baseUrl}/delete/${id}`);
    } catch (error) {
        console.log(error);
        throw new Error('Failed to delete preference');
    }
}