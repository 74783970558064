import React from 'react';
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import ImageLink from "./ImageLink";

AppButtons.propTypes = {};

function AppButtons() {

    const isAndroidPhone = () => isAndroid && isMobile;
    const isIPhone = () => isIOS && isMobile;
    const isOther = () => !isAndroidPhone() && !isIPhone();

    const googlePlayLink = () => <ImageLink height="3.5em"
                                            url='https://play.google.com/store/apps/details?id=app.sonr'
                                            imageSrc={require("../assets/google_play.png")}> </ImageLink>
    const appleAppStoreLink = () => <ImageLink height="3.5em" url='https://apps.apple.com/app/sonr/id6446257063?platform=iphone'
                                               imageSrc={require("../assets/app_store.png")}> </ImageLink>

    return (
        <div className="d-flex justify-content-evenly">
            {(isIPhone() || isOther()) && appleAppStoreLink()}
            {(isAndroidPhone() || isOther()) && googlePlayLink()}
        </div>
    );
}

export default AppButtons;