import {MyColumn} from "../interface/MyColumn";
import {ImageSize} from "../Constants";
import {User} from "../interface/User";

export function transformTextToColumn(text: string): MyColumn {
    const [title, ...body] = text.split(": ");
    return {title, body: body.join(": ").charAt(0).toUpperCase() + body.join(": ").slice(1)};
}

export function urlToResizedImageUrl(url: string, size: string = ImageSize.md): string {
    if (!url || url.length < 1) {
        return '';
    }
    if (url.startsWith(
        'https://firebasestorage.googleapis.com/v0/b/nearby-2a601.appspot.com/o/test%2Fprofile')) {
        return url;
    }
    let pos = url.lastIndexOf('.');
    return url.substring(0, pos) + `_${size}.jpg?alt=media`;
}

export function dateTimeStringFromTimestamp(timestamp: number) {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString('sv-SE'); // Format: MM/DD/YYYY
    const formattedTime = date.toLocaleTimeString('sv-SE'); // Format: HH:MM:SS

    return formattedDate + ' - ' + formattedTime;
}

export function getMobilePortrait() {
    return window.matchMedia("(max-width: 480px) and (orientation: portrait)").matches;
}

export function distanceKm(latLng1: number[], latLng2: number[]): number {
    // Earth radius in kilometers
    const R = 6371.01;

    // Convert latitude and longitude to radians
    const lat1Radians = Math.PI * latLng1[0] / 180;
    const lon1Radians = Math.PI * latLng1[1] / 180;
    const lat2Radians = Math.PI * latLng2[0] / 180;
    const lon2Radians = Math.PI * latLng2[1] / 180;

    // Calculate the distance between the two points
    const dLat = lat2Radians - lat1Radians;
    const dLon = lon2Radians - lon1Radians;
    const a = Math.sin(dLat / 2) ** 2 + Math.cos(lat1Radians) * Math.cos(lat2Radians) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
}


export function isAgeInRange(user: User, otherUser: User): boolean {
    const {age} = user;
    const {rangeAge} = otherUser;
    const [minAge, maxAge] = rangeAge;
    return age >= minAge && age <= maxAge;
}

export function hasReasonHereMatch(user: User, otherUser: User): boolean {
    const {reasonHere} = user;
    const {reasonHere: otherReasonHere} = otherUser;
    return reasonHere.some((reason) => otherReasonHere.includes(reason));
}

export function isLookingForMatch(user: User, otherUser: User): boolean {
    const {iAm} = user;
    const {lookingFor} = otherUser;
    return lookingFor.includes(iAm);
}

export function isWithinDistance(user: User, otherUser: User): boolean {
    const distance = distanceKm(user.latLng, otherUser.latLng);
    return distance <= otherUser.rangeDistanceMtr / 1000;
}

export function possibleMatch(user1: User, user2: User): boolean {
    const isMatchAgeInRange = isAgeInRange(user1, user2);
    const hasMatchInReasonHere = hasReasonHereMatch(user1, user2);
    const isMatchLookingFor = isLookingForMatch(user1, user2);
    const isMatchWithinDistance = isWithinDistance(user1, user2);

    // Final result: age is within rangeAge, reasonHere has at least one match, lookingFor includes iAm, and they are within the specified distance.
    return isMatchAgeInRange && hasMatchInReasonHere && isMatchLookingFor && isMatchWithinDistance;
}