import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style/index.scss';
import './i18n';
import {
    createHashRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./page/ErrorPage";
import {routeName} from "./Constants";
import MainContent from "./page/MainContent";
import AdminPage from "./page/admin/AdminPage";
import AuthProvider from "./provider/AuthProvider";
import ReportPage from "./page/admin/ReportPage";
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import PrivacyPage from "./page/PrivacyPage";
import TermsPage from "./page/TermsPage";
import CookiesPage from "./page/CookiesPage";
import AdminMenu from "./page/admin/AdminMenu";
import UsersPage from "./page/admin/UsersPage";
import PreferencePage from "./page/admin/PreferencePage";
import AccountDeletion from "./page/account_deletion/AccountDeletion";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient()
const router = createHashRouter([
    {
        path: routeName.root + routeName.privacy,
        element: <PrivacyPage/>,
    },
    {
        path: routeName.root + routeName.terms,
        element: <TermsPage/>,
    },
    {
        path: routeName.root + routeName.cookies,
        element: <CookiesPage/>,
    },
    {
        path: routeName.root + routeName.accountDeletion,
        element: <AccountDeletion/>,
    },
    {
        path: routeName.root + routeName.admin,
        element: <AdminPage/>,
        children: [
            {
                index: true,
                element: <AdminMenu/>
            }
            ,
            {
                path: routeName.users,
                element: <UsersPage/>
            }
            ,
            {
                path: routeName.report,
                element: <ReportPage/>
            },
            {
                path: routeName.report + '/:reportId',
                element: <ReportPage/>
            },
            {
                path: routeName.preference,
                element: <PreferencePage/>
            }
        ]
    },
    {
        path: routeName.root,
        element: <App/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                index: true,
                element: <MainContent/>
            },
            {
                path: ':arg',
                element: <MainContent/>
            }
        ]
    },
]);


root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <RouterProvider router={router}/>
            </AuthProvider>
        </QueryClientProvider>
    </React.StrictMode>
);

