import React, {useEffect, useRef, useState} from 'react';
import {Carousel, Col, Container, Row} from "react-bootstrap";
import FadeTransSection from "../component/FadeTransSection";
import {useTranslation} from "react-i18next";
import {useInView} from "react-intersection-observer";
import AppImgCarousel from "../component/AppImgCarousel";
import AppButtons from "../component/AppButtons";
import AppImageRow from "../component/AppImageRow";
import {getMobilePortrait, transformTextToColumn} from "../utils/functions";
import {useWindowSize} from "../hook/UseWindowSize";


AppPage.propTypes = {};


function AppPage() {
    const {t, i18n} = useTranslation();
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });
    const [isMobilePortrait, setIsMobilePortrait] = useState(getMobilePortrait);
    const windowSize = useWindowSize();
    const images = [];
    for (let i = 0; i <= 4; i++) {
        images.push(
            require(`../assets/img/screen${i}.png`)
        );
    }

    useEffect(() => {
        function setIsMobile() {
            let isMobile = getMobilePortrait();
            setIsMobilePortrait(isMobile)
        }
        console.log(isMobilePortrait)
    }, [windowSize])


    const pointData = t('page.app.points');
    const points = pointData ? JSON.parse(pointData) : [];
    const half = Math.ceil(points.length / 2);
    const firstList = points.slice(0, half);
    const secondList = points.slice(half, points.length);


    return (
        <Container id={'app-page'}>
            <Row ref={ref}>

                <Col>
                    <FadeTransSection start={inView} fade>
                        {isMobilePortrait ? <AppImgCarousel images={images}/> : <AppImageRow images={images}/>}
                    </FadeTransSection>
                </Col>
            </Row>
            <FadeTransSection start={inView} translate={'-100vw'} delay={1000}>
                <Row className={"app-description "}>
                    <Col>

                        <div className={"d-flex flex-column justify-content-between"}>
                            <ul>  {firstList.map((str: string, index: number) => {
                                let col = transformTextToColumn(str);

                                return <li key={`first_${index}`}>
                                    <b>{col.title}</b> <p>{col.body}</p>
                                </li>
                            })}</ul>
                        </div>

                    </Col> <Col>
                    <div className={"d-flex flex-column justify-content-between"}>
                        <ul>  {secondList.map((str: string, index: number) => {
                            let col = transformTextToColumn(str);

                            return <li key={`second_${index}`}>
                                <b>{col.title}</b> <p>{col.body}</p>
                            </li>
                        })}</ul>

                    </div>

                </Col>
                </Row>
            </FadeTransSection>
            <Row>

                <Col>
                    <div className={"mt-5"}>
                        <AppButtons/>
                    </div>
                </Col></Row>
        </Container>
    );
}

export default AppPage;