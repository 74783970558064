import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Image} from "react-bootstrap";
import {dateTimeStringFromTimestamp, distanceKm, possibleMatch, urlToResizedImageUrl} from "../utils/functions";
import {Gender, ImageSize, Reason} from "../Constants";
import MySimpleMap from "./MySimpleMap";
import {User} from "../interface/User";
import {GeoAlt, GeoAltFill, PersonLinesFill} from "react-bootstrap-icons";

UserListItem.propTypes = {};

interface Props {
    user: User
    setCheckUser: (user: User) => void
    checkUser: User | null
    showUser: (userId: string) => void
}

function UserListItem(props: Props) {

    const {user, showUser, setCheckUser, checkUser} = props;


    const isPossibleMatch = function () {
        if(!checkUser) return  false;
        return  possibleMatch(user, checkUser) && possibleMatch(checkUser, user);
    };

    const cardBorder = (isPossibleMatch() || checkUser?.id === user.id) ? "bg-success" : "";

    return <Card text={'dark'}
                 className={" d-flex flex-row p-1 " + cardBorder }  >
        <Image roundedCircle width={'75px'} height={'75px'} style={{objectFit: "cover"}}
               src={urlToResizedImageUrl(user.profileImgUrl, ImageSize.md)}/>
        <Card.Body>
            <div className="d-flex flex-row justify-content-end">
                <Button onClick={() => showUser(user.id)}>
                    <PersonLinesFill/>
                </Button>
                <Button onClick={() => {
                    setCheckUser(user);
                }}>
                    {user.id === checkUser?.id ? <GeoAltFill/> : <GeoAlt/>}
                </Button>
            </div>
            <Card.Title>{user.displayName}</Card.Title>
            <Card.Text>{user.email}</Card.Text>
            <Card.Text>Age: {user.age}, looks for {user.rangeAge.join('-')}</Card.Text>
            <Card.Text>
                I am: {Gender[user.iAm]}
            </Card.Text>
            <Card.Text>
                Interested in: {user.lookingFor.map(gender => Gender[gender]).join(', ')}
            </Card.Text>
            <Card.Text>
                Reach: {(user.rangeDistanceMtr / 1000).toFixed(1)} km {checkUser && ', distance: ' + distanceKm(user.latLng, checkUser.latLng).toFixed(2) + ' km'}
            </Card.Text>
            <Card.Text>
                Here for: {user.reasonHere.map(reason => Reason[reason]).join(', ')}
            </Card.Text>
            <Card.Subtitle>
                Created At: {dateTimeStringFromTimestamp(user.createdAt)}
            </Card.Subtitle>
            <Card.Subtitle>
                Last active: {dateTimeStringFromTimestamp(user.lastActive)}
            </Card.Subtitle>
        </Card.Body>
        <div style={{width: "150px"}} className="d-flex flex-column">
            {!user.latLng.every((val, index) => val === [0.0, 0.0][index]) &&
            <MySimpleMap latLng={user.latLng}/>}
            <div className="d-flex justify-content-center">
                <span>
                    {user.latLng[0]} <br/>
                    {user.latLng[1]}
                </span>
            </div>
        </div>
    </Card>
}

export default UserListItem;