import React, {useEffect, useState} from 'react';
import { useSpring, animated, config } from '@react-spring/web';


interface Props {
    text : string
    containerClassName: string
    textClassName : string
}

function ScaleFadeText(elementProp : Props) {
    const {text, containerClassName, textClassName} = elementProp;
    const [clicked, setClicked] = useState(false);
    const [springProps, setSpringProps] = useSpring(() => ({
        scale: 0.3,
        opacity: 0,
    }));

    const handleClick = () => {
        setClicked(true);
        setSpringProps({
            scale: 1.1,
            config: config.wobbly,
            onRest: () => {
                setSpringProps({
                    scale: 1.0,
                    config: { tension: 250, friction: 20 },
                    delay: 200,
                });
            },
        });
    };

    const handleMouseEnter = () => {
        setSpringProps({
            scale: 0.95,
            opacity: 0.8,
            config: config.default,
        });
    };

    const handleMouseLeave = () => {
        setSpringProps({
            scale: clicked ? 1.1 : 1.0,
            opacity: 1,
            config: config.default,
        });
    };


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSpringProps({
                opacity: 1,
                scale: 1,
                config: { tension: 250, friction: 20 },
            });
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [setSpringProps]);

    return (
        <div className={containerClassName}
             onClick={handleClick}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
        >
            <animated.h1
                className={textClassName}
                style={{
                    opacity: springProps.opacity,
                    transform: springProps.scale.to((s) => `scale(${s})`),
                }}
            >
                {text}
            </animated.h1>
        </div>
    );
}

export default ScaleFadeText;