import {getData} from "./client";
import {Report} from "../interface/Report";

export async function getReportById(id: string | undefined, signal: AbortSignal | null = null): Promise<Report> {
    try {
        let result = await getData(`${baseUrl}/${id}`);
        return await result.json();
    } catch (e) {
        console.log(e);
        throw e;
    }
}


const baseUrl = '/report'