import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Modal, ModalProps, Row} from 'react-bootstrap';
import {MyPreference} from "../interface/MyPreference";
import {deletePreference, updatePreference} from "../repository/PreferenceRepository";
import MySpinner from "../component/MySpinner";
import {PrefUpdateDto} from "../interface/PrefUpdateDto";

PrefEditModal.propTypes = {};

interface Props extends ModalProps {
    prefDto: PrefUpdateDto | null
    onEdit: (pref: MyPreference) => void;
}

const initialState = {lng: '', value: '', id: ''};

function PrefEditModal(props: Props) {
    const {onEdit, prefDto, ...modalProps} = props;
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [prefToUpdate, setPrefToUpdate] = useState<PrefUpdateDto>(initialState);

    useEffect(
        () => {
            setPrefToUpdate(prefDto ?? initialState);
        }, [prefDto]
    )

    const handleEditClick = async () => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            const result = await updatePreference(prefToUpdate);
            props.onEdit(result);
        } catch (e: any) {
            setError(e.toString());
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <Modal className={"text-dark"} {...modalProps} size="lg" aria-labelledby="contained-modal-title-vcenter"
               centered>
            {isLoading ? <div className={"d-flex justify-content-center align-items-center"}><MySpinner/></div> : <>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Update preference</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Id: {prefToUpdate.id}</h4>
                    <Form.Group as={Row} className="mb-3" controlId={prefToUpdate.lng} key={prefToUpdate.lng}>
                        <Form.Label column sm="2">
                            {prefToUpdate.lng}
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                placeholder={prefToUpdate.lng}
                                value={prefToUpdate.value}
                                onChange={(e) =>
                                    setPrefToUpdate((dto) => ({
                                        ...dto,
                                        value: e.target.value
                                    }))
                                }
                            />
                        </Col>
                    </Form.Group>
                    {error && <Alert variant={'danger'}>{error}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Cancel</Button>
                    <Button onClick={handleEditClick}>Edit</Button>
                </Modal.Footer>
            </>}
        </Modal>
    );
}

export default PrefEditModal;
