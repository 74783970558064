import React from 'react';
import PropTypes from 'prop-types';

Separator.propTypes = {

};

function Separator() {
    return (
        <div className="w-100 d-flex justify-content-center my-3">
            <div style={{backgroundColor: "lightgray", width: "100%", height: "1px"}}/>
        </div>
    );
}

export default Separator;