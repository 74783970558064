import React, {useState} from 'react';
import {Report} from "../interface/Report";
import {Alert, Button, Card, Col, Image, Container, Row} from "react-bootstrap";
import UserProfileMail from "./UserProfileMail";
import Separator from "./Separator";
import {disableUser} from "../repository/UserRepository";
import MySpinner from "./MySpinner";
import {urlToResizedImageUrl} from "../utils/functions";
import {ImageSize} from "../Constants";
import ReportUserTabs from "./ReportUserTabs";
import UserProfileCard from "./UserProfileCard";
import {User} from "../interface/User";

interface Props {
    report: Report
    setReport: React.Dispatch<React.SetStateAction<Report | null>>
}

function ReportCard(props: Props) {
    const {setReport, report} = props;
    const {subject, description, reporter, regarding} = report;


    return (
        <Container>
            <Card.Body>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Reporter</Card.Title>
                                <UserProfileMail user={reporter}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Separator/>
                <Row>
                    <Col>
                        <span>
                            <b>{reporter.displayName}</b> has reported <b>{regarding.displayName}</b> for <b>{subject}</b>
                        </span>
                        <p>Description: {description} </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <UserProfileCard callback={(user : User)=>{
                            setReport((oldState: any) => ({
                                ...oldState,
                                regarding: user
                            }));
                        }} user={report.regarding}/>
                    </Col>
                </Row>
            </Card.Body>
        </Container>
    );
}

export default ReportCard;