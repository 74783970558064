import {getData} from "./client";
import {MyMessage} from "../interface/MyMessage";


export async function getMessagesByUser(id: string) : Promise<MyMessage[]>{

    try{
        let result = await getData(`${baseUrl}/by-user/${id}`);
       return  await result.json();
    }catch(e){
        console.log(e);
        throw e;
    }
}




const baseUrl = '/chat-message'



