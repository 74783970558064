import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Row, Table} from 'react-bootstrap';
import {MyPreference} from '../../interface/MyPreference';
import {getAllPreference} from '../../repository/PreferenceRepository';
import MySpinner from '../../component/MySpinner';
import {PencilSquare, PlusCircle, Trash} from "react-bootstrap-icons";
import PrefAddModal from "../../modal/PrefAddModal";
import PrefDeleteModal from "../../modal/PrefDeleteModal";
import {PrefUpdateDto} from "../../interface/PrefUpdateDto";
import PrefEditModal from "../../modal/PrefEditModal";

function PreferencePage() {
    const [prefs, setPrefs] = useState<MyPreference[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [preferenceKeys, setPreferenceKeys] = useState<string[]>([]);
    const [showCreatePrefModal, setShowCreatePrefModal] = useState<boolean>(false);
    const [showEditPrefModal, setShowEditPrefModal] = useState<PrefUpdateDto | null>(null);
    const [showDeletePrefModal, setShowDeletePrefModal] = useState<MyPreference | null>(null);


    useEffect(() => {
        getAllPrefs();
    }, []);

    async function getAllPrefs() {
        if (isLoading) return;
        setIsLoading(true);
        if (error != null) {
            setError(null);
        }
        try {
            const result = await getAllPreference();
            setPrefs(result);
            setPreferenceKeys(getUniqueKeys(result));
        } catch (error: any) {
            setError(error.toString());
        } finally {
            setIsLoading(false);
        }
    }

    function getUniqueKeys(preferences: MyPreference[]): string[] {
        const allKeys: string[] = [];
        preferences.forEach((pref) => {
            Object.keys(pref.values).forEach((key) => {
                if (!allKeys.includes(key)) {
                    allKeys.push(key);
                }
            });
        });
        return allKeys.sort();
    }

    function handleAdd(pref: MyPreference) {
        setShowCreatePrefModal(false);
        setPrefs((oldState) => [...oldState, pref].sort((a, b) => a.id.localeCompare(b.id)));
    }

    function handleEdit(pref: MyPreference) {
        setShowEditPrefModal(null);
        setPrefs((oldState) =>
            oldState.map((oldPref) => (oldPref.id === pref.id ? pref : oldPref))
        );
    }

    function handleDelete() {
        setPrefs((oldState) => [...oldState].filter((e) => e.id !== showDeletePrefModal?.id));
        setShowDeletePrefModal(null);
    }

    if (isLoading && prefs.length === 0) {
        return (
            <div className="justify-content-center align-items-center">
                <MySpinner/>
            </div>
        );
    }


    return (
        <Container>
            <Row>
                <Col>
                    <Button onClick={() => setShowCreatePrefModal(true)}>
                        <PlusCircle size={23}/>
                    </Button>
                </Col>
            </Row>
            <div style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 150px)'}}>
                <Table striped bordered hover className={'bg-light'}>
                    <thead>
                    <tr>
                        <th>Id</th>
                        {preferenceKeys.map((key) => (
                            <th key={key}>{key}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {prefs.map((pref, index) => (
                        <tr key={index}>
                            <td>{pref.id} <Trash onClick={() => setShowDeletePrefModal(pref)}
                                                 style={{cursor: 'pointer'}}/></td>
                            {preferenceKeys.map((key) => (

                                <td key={key}>{pref.values[key]} <PencilSquare onClick={() => setShowEditPrefModal({
                                    id: pref.id,
                                    value: pref.values[key],
                                    lng: key
                                })} style={{cursor: 'pointer'}}/></td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
            <PrefEditModal show={showEditPrefModal != null} onHide={() => setShowEditPrefModal(null)}
                          onEdit={handleEdit} prefDto={showEditPrefModal}/>
            <PrefAddModal show={showCreatePrefModal} onHide={() => setShowCreatePrefModal(false)}
                          ids={prefs.map((e) => e.id)}
                          onAdd={handleAdd} lngKeys={preferenceKeys}/>
            <PrefDeleteModal show={showDeletePrefModal != null} onHide={() => setShowDeletePrefModal(null)}
                             onDelete={() => handleDelete()}
                             pref={showDeletePrefModal}/>
        </Container>
    );
}

export default PreferencePage;
