import React from 'react';
import PropTypes from 'prop-types';
import {Card, Nav, NavDropdown} from "react-bootstrap";
import {Gender, order} from "../Constants";
import {User} from "../interface/User";

UserListHeader.propTypes = {};

const orderByOptions: { [key: string]: string } = {
    createdAt: 'Last created',
    lastActive: 'Last active',
};




interface Props {
    users: User[]
    orderBy: string
    setOrderBy: (order: string) => void
    fetchUsers: (from: number | null, oldState: User[], orderBy: string) => Promise<void>
}

function UserListHeader(props: Props) {
    const {users, orderBy, setOrderBy, fetchUsers} = props;

    async function handleOrderByClick(selectedOrderBy: string) {
        if (selectedOrderBy !== orderBy) {
            setOrderBy(selectedOrderBy);
            await fetchUsers(null, [], selectedOrderBy);
        }
    }

    // Function to calculate the median age of users
    const calculateMedianAge = (): number => {
        const ages = users.map((user) => user.age);
        ages.sort((a, b) => a - b);

        const midIndex = Math.floor(ages.length / 2);
        if (ages.length % 2 === 0) {
            return (ages[midIndex - 1] + ages[midIndex]) / 2;
        } else {
            return ages[midIndex];
        }
    };

    // Function to calculate the median age range
    const calculateMedianAgeRange = (): string => {
        const minAges = users.map((user) => user.rangeAge[0]);
        const maxAges = users.map((user) => user.rangeAge[1]);

        minAges.sort((a, b) => a - b);
        maxAges.sort((a, b) => a - b);

        const midIndex = Math.floor(minAges.length / 2);
        const medianMinAge = minAges.length % 2 === 0 ? (minAges[midIndex - 1] + minAges[midIndex]) / 2 : minAges[midIndex];
        const medianMaxAge = maxAges.length % 2 === 0 ? (maxAges[midIndex - 1] + maxAges[midIndex]) / 2 : maxAges[midIndex];

        return `${medianMinAge}-${medianMaxAge}`;
    };

    const calculateGenderPercentage = (): string => {
        const genderCount: { [key in Exclude<Gender, Gender.None>]: number } = {
            [Gender.Male]: 0,
            [Gender.Female]: 0,
            [Gender.NonBinary]: 0,
            [Gender.TransMale]: 0,
            [Gender.TransFemale]: 0,
            [Gender.Other]: 0,
        };

        users.forEach((user) => {
            if (user.iAm !== Gender.None) {
                genderCount[user.iAm]++;
            }
        });

        const totalUsers = users.length;
        const genderPercentages = Object.entries(genderCount).map(([gender, count]) => {
            const percentage = (count / totalUsers) * 100;
            return `${Gender[gender as unknown as Gender]}: ${percentage.toFixed(2)}%`;
        });

        return genderPercentages.join(", ");
    };

    const calculateMostPopularGenders = (): string => {
        const genderCount: { [key in Exclude<Gender, Gender.None>]: number } = {
            [Gender.Male]: 0,
            [Gender.Female]: 0,
            [Gender.NonBinary]: 0,
            [Gender.TransMale]: 0,
            [Gender.TransFemale]: 0,
            [Gender.Other]: 0,
        };

        users.forEach((user) => {
            user.lookingFor.forEach((gender) => {
                if (gender !== Gender.None) {
                    genderCount[gender]++;
                }
            });
        });

        const totalUsers = users.length;
        const mostPopularGenders = Object.entries(genderCount)
            .sort(([, countA], [, countB]) => countB - countA)
            .map(([gender, count]) => {
                const percentage = (count / totalUsers) * 100;
                return `${Gender[parseInt(gender)]}: ${percentage.toFixed(2)}%`;
            });

        return mostPopularGenders.join(", ");
    };


    return (
        <div className={'d-flex flex-column'}>
            <div>
                <Card text={'dark'}>
                    <Card.Body>
                        <Card.Title>Summary</Card.Title>
                        <Card.Text>
                            <b>Gender Representation:</b> {calculateGenderPercentage()} <br/>
                            <b>Median Age: </b>{calculateMedianAge()} <br/>
                            <b>Median Age Range: </b>{calculateMedianAgeRange()} <br/>
                            <b>Percentage of users looking for: </b>{calculateMostPopularGenders()} <br/>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
            <Nav variant="pills" activeKey="1">
                <NavDropdown title={orderByOptions[orderBy]} id="nav-dropdown">
                    <NavDropdown.Item active={orderBy === order.createdAt} onClick={() => handleOrderByClick(order.createdAt)}
                                      eventKey="4.1">{orderByOptions[order.createdAt]}</NavDropdown.Item>
                    <NavDropdown.Item active={orderBy === order.lastActive} onClick={() => handleOrderByClick(order.lastActive)}
                                      eventKey="4.2">{orderByOptions[order.lastActive]}</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </div>
    );
}

export default UserListHeader;