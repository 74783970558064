import {lang} from "./interface/Lang";

// export const API_BASE_URL = 'http://localhost:5003';
 export const API_BASE_URL = ' https://sonr-back.herokuapp.com';

export const appName = 'SonR';

export const order = {
    createdAt: 'createdAt',
    lastActive: 'lastActive'

}

export const routeName = {
    root: '/',
    notFound: '*',
    home: 'home',
    app: 'app',
    faq: 'faq',
    about: 'about',
    terms: 'terms-of-usage',
    cookies: 'cookies-policy',
    privacy: 'privacy-policy',
    admin: 'admin',
    report: 'report',
    preference: 'preference',
    users: 'users',
    accountDeletion: 'account-deletion'
}

export const ImageSize = {
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
}

export const lngCode = {
    sv: 'sv',
    en: 'en'
}

export enum Gender {
    None = 0,
    Male = 1,
    Female = 2,
    NonBinary = 3,
    TransMale = 4,
    TransFemale = 5,
    Other = 6,
}

export enum Reason {
    None = 0,
    Friendship = 1,
    Love = 2
}


export const I18nLng = new Map<string, lang>([
    [lngCode.sv, {nativeName: 'Svenska'}],
    [lngCode.en, {nativeName: 'English'}]
]);